'use strict'

angular
  .module 'lpaFastTracking'
  .config ($stateProvider) ->
    $stateProvider
      .state 'fastTracking',
        url: '/tracking/{unit_id}',
        templateUrl: 'lpa/lpa-fast-tracking/views/lpa-fast-tracking.tpl.html'
        controller: 'FastTrackingCtrl'
        controllerAs: 'fastTracking'
        onEnter: [
          'MundoSocket'
          '$stateParams'
          (MundoSocket, $stateParams) ->
            MundoSocket.toggleUnitStatusConsumer true, [$stateParams['unit_id']]
            MundoSocket.toggleUnitEventConsumer true, [$stateParams['unit_id']]
            MundoSocket.toggleTenantEventConsumer true
        ]
        onExit: [
          'MundoSocket'
          '$stateParams'
          (MundoSocket, $stateParams) ->
            MundoSocket.toggleUnitStatusConsumer false, [$stateParams['unit_id']]
            MundoSocket.toggleUnitEventConsumer false, [$stateParams['unit_id']]
            MundoSocket.toggleTenantEventConsumer false
        ]
        resolve:
          DispUnitTypes: (DispatchUnitTypes, RestUtils) ->
            RestUtils.getFullList DispatchUnitTypes
          DispUnitStatuses: (DispatchUnitStatus, RestUtils) ->
            RestUtils.getFullList DispatchUnitStatus
        data:
          navigation: false
